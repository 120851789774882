@import "app/constants.scss";

.frame {
  padding-bottom: 12px;
}

.tagContainer {
  margin: 24px 0 0;
  text-align: center;
}
