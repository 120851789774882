@import "app/constants";

.frame {
  color: $DARK_THEME_TEXT_COLOR;
  align-items: center;
  height: calc(100vh - 54px);
}

.wrapper {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
}

.view {
  text-align: center;

  .img {
    max-width: 400px;
    width: 100%;
  }
}

.stepper {
  background-color: $BACKGROUND_BLACK !important;
}

.buttonContainer {
  margin-top: 16px;
  text-align: center;
}
