@import "../../../app/constants";

.frame {
  background-color: black;
  overflow: hidden;

  .inner {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .adContainer {
    height: calc(25vw * 9 / 16);
    position: relative;
  }

  .ad {
    display: inline-block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    overflow-x: scroll;
    .adContainer {
      height: calc(40vw * 9 / 16);
    }

    .inner {
      grid-template-columns: 40vw 40vw 40vw 40vw;
    }
  }

  @media screen and (max-width: 500px) {
    overflow-x: scroll;
    .adContainer {
      height: calc(60vw * 9 / 16);
    }

    .inner {
      grid-template-columns: 60vw 60vw 60vw 60vw;
    }
  }
}
