@import "../../../app/constants";

.frame {
  background-color: #111;
  height: 400px;
  position: relative;

  @media screen and (max-width: $MOBILE_MAX_WIDTH) {
    height: 80vh;
  }
}

.image {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 70vw;

  @media screen and (max-width: $MOBILE_MAX_WIDTH) {
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: .8s;
    width: 100%;

    &.loaded {
      opacity: 1;
    }
  }

  .overlay {
    background: linear-gradient(270deg, #1110 0%, rgba(17,17,17,1) 100%);
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    left: 0;

    @media screen and (max-width: $MOBILE_MAX_WIDTH) {
      background: linear-gradient(180deg, #1110 0%, rgba(17,17,17,1) 100%);
      width: 100%;
    }
  }
}

.frame:nth-child(2n) {
  .image {
    left: 0;
    .overlay {
      background: linear-gradient(90deg, #1110 0%, #111f 100%);
      @media screen and (max-width: $MOBILE_MAX_WIDTH) {
        background: linear-gradient(180deg, #1110 0%, #111f 100%);
      }
    }
  }
  .meta {
    left: initial;
    right: 24px;
    @media screen and (max-width: $MOBILE_MAX_WIDTH) {
      right: initial;
    }
  }
}

.meta {
  color: white;
  left: 24px;
  position: absolute;
  bottom: 32px;
  width: 45vw;

  @media screen and (max-width: $MOBILE_MAX_WIDTH) {
    bottom: 16px;
    left: 0;
    padding: 0 16px;
    width: initial;
  }

  .title {
    word-break: break-all;
    font-size: 24px;

    @media screen and (max-width: $MOBILE_MAX_WIDTH) {
      font-size: 20px;
    }
  }

  .user {
    margin-bottom: 12px;
    margin-top: 4px;
    padding: 6px 0 6px 44px;
    position: relative;

    .text {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .iconContainer {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .description {
    font-size: 12px;
    margin-top: 12px;
  }

  .buttonContainer {
    margin-top: 12px;

    button {
      margin-right: 8px;

      svg {
        margin-right: 8px;
      }
    }
  }
}
