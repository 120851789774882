@import 'app/constants.scss';

.frame {
  padding-bottom: 12px;
  padding-top: calc(68px + env(safe-area-inset-top, 0px));
  text-align: center;
}

.sns {
  margin: 8px 0 16px;
}
