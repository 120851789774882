@import "../../../app/constants.scss";

.frame {
  position: relative;
}

.contentContainer {
  display: flex;

  .iconContainer {
    cursor: pointer;
    left: 0;
    position: absolute;
  }
  
  .textContainer {
    padding-left: 64px;
  }
  
  .buttonContainer {
    position: absolute;
    right: 8px;
  }
}

.usernmae {
  cursor: pointer;
  // ここのフォントはカスタムで、Material Typography にない
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 20px;
  margin-bottom: 8px;

  a {
    color: white;
  }
}

.date {
  @include BODY_2;
}
