@import "app/constants.scss";

// 横幅が小さい時はリスト表示
// 広い時はタイル状にユーザーリストを表示する
.cellContainer {
  color: white;
  display: inline-block;
  position: relative;
  text-align: left;
  padding: 12px 15px;
  text-align: center;

  @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
    display: block;
    text-align: left;
    padding: 10px 0 10px 86px;
  }

  .iconContainer {
    cursor: pointer;

    @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
      position: absolute;
      left: 20px;
      top: 3px;
    }
  }

  .textRowContainer {
    @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
      display: block;
    }

    .usernameLine {
      cursor: pointer;
      font-weight: 500;
      line-height: 26px;
      margin: 4px 0;

      &:hover {
        text-decoration: underline;
      }

      @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
        margin: 0 0 4px;
      }
    }

    @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
      margin: 4px 0 0;
    }
  }

  .followButtonContainer {
    @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
      position: absolute;
      right: 20px;
      top: 7px;
    }
  }
}
