@import "app/constants";

.wrapper {
  display: none;

  &.show {
    display: initial;
  }
}

.cover {
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: .4s;
  width: 100%;
  z-index: $ZINDEX_DIALOG;

  &.showContent {
    background-color: rgba(0, 0, 0, .6);
  }
}

.frame {
  background-color: $BACKGROUND_BLACK;
  border-radius: 4px;
  color: white;
  opacity: 0;
  position: relative;
  transition: .4s;
  transition-delay: .2s;
  width: 800px;

  .openInMobile {
    @include CAPTION;

    background-color: rgba(0,0,0,.6);
    border-radius: 2px;
    color: white;
    height: 25px;
    left: 0;
    margin: auto;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 20px;
    text-align: center;
    width: 190px;
    z-index: 1000;
  }

  &.show {
    opacity: 1;
  }

  @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
    border-radius: 16px 16px 0 0;
    bottom: -500px;
    position: absolute;
    width: 100%;

    &.show {
      bottom: 0;
    }
  }
}

.header {
  padding: 12px 24px;

  .title {
    @include H6;
  }
}

.markerContainer {
  background-color: #ccc;
  background-size: cover;
  padding-top: 56.25%;
  position: relative;
  width: 100%;

  img {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: .4s;
    width: 100%;

    &.loaded {
      opacity: 1;
    }
  }
}

.description {
  @include CAPTION;

  a {
    color: $BLUE;
  }
}

.footer {
  padding: 12px 24px 16px;
  position: relative;
}

.buttonContainer {
  color: white;
  position: absolute;
  right: 8px;
  top: 10px;

  button {
    color: white;

    &:first-child {
      margin-right: 8px;
    }
  }

  @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
    top: 8px;
    right: 4px;
  }
}
