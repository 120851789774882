@import 'app/constants';

.frame {
  background-color: $BACKGROUND_BLACK;
  color: $DARK_THEME_TEXT_COLOR;
  padding-bottom: 12px;
}

.documentWrapper {
  margin: 0 auto;
  padding-top: 16px;
  position: relative;
  width: 820px;

  @media only screen and (max-width: #{$TABLET_MAX_WIDTH}) {
    width: 600px;
    margin: 0 auto;
  }

  @media only screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
    width: initial;
    margin: 0 16px;
  }

  .statsRow {
    position: relative;
  }

  .goodIcon {
    display: inline-block;
  }

  .goodCount {
    display: inline-block;
    font-size: 16px;
    margin: 0 4px;
  }

  .shareIcon {
    float: right;
  }
}

.title {
  @include H4;
  width: calc(100% - 180px);
  word-break: break-all;

  @media only screen and (max-width: #{$TABLET_MAX_WIDTH}) {
    @include H6;
    width: initial;
  }
}

.userSection {
  padding: 16px 0;
  position: relative;
}

.description {
  font-size: 14px;
  margin-bottom: 24px;
  position: relative;
  word-wrap: break-word;

  .header {
    @include BODY_1;
    margin-bottom: 8px;
  }

  a {
    color: $BLUE;
  }

  img {
    max-width: 100%;
  }
}

.recommended {
  padding-bottom: 1px;
  margin-top: 48px;

  .header {
    @include H6;
    color: $DARK_THEME_TEXT_COLOR;
    padding-bottom: 12px;
    position: relative;
    text-align: center;
  }
}

.publishStatus {
  margin-top: 8px;

  .accessLevel {
    background: rgba(224, 224, 224, 0.35);
    border-radius: 2px;
    display: inline;
    padding: 0 5px 2px 4px;

    .icon {
      display: inline-block;
      height: 12px;
      margin-right: 4px;
      width: 12px;
    }

    .text {
      @include BODY_1;
      color: #FFFFFF;
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
    }
  }

  .draftMessage {
    @include CAPTION;
    margin-left: 8px;
  }
}
