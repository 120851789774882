@import "app/constants.scss";

.frame {
  border-radius: 100% !important;
  height: 48px;
  overflow: hidden;
  position: relative;
  display: inline-block;

  .socialIcon {
    background-color: $BACKGROUND_BLACK;
    display: inline-block;
    font-size: 19px;
    height: 32px;
    margin: 0 8px;
    opacity: .5;
    padding: 6px 0;
    text-align: center;
    width: 32px;

    &.hasProfile {
      opacity: 1;
      padding: 7px 0;
      cursor: pointer;
    }

    svg {
      height: 18px;
      vertical-align: top;
      width: 18px;
    }
  }
}
