@import "../../../app/constants.scss";

.frame {
  background-color: $BACKGROUND_BLACK;
  height: 60vh;
  position: relative;
}

.thumbnailContainer {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.thumbnail {
  height: 120%;;
  font-family: 'object-fit: cover;';
  object-fit: cover;
  opacity: 0;
  transition: .3s;
  width: 120%;
  position: absolute;
  left: -10%;
  top: 0%;

  &.visible {
    opacity: 1;
  }
}

.cover {
  background: linear-gradient(0deg, #111f 0%, #1110 50%);
  height: 100%;
  left: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 100%;
}

svg.playIcon {
  height: 100px;
  left: calc(50% - 50px);
  position: absolute;
  top: calc(50% - 50px);
  width: 100px;
}
