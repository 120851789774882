@import "app/constants.scss";

.frame {
  text-align: center;
  padding: 8px 0 16px;
}

.username {
  @include H5;
  margin-top: 4px;
}

.counts {
  margin-top: 8px;
  .totalScenes {
    padding-right: 8px;
  }
}

.sns {
  margin-top: 8px;
}
