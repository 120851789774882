@import "app/constants.scss";

button.followButton {
  color: $DARK_THEME_TEXT_COLOR;
  background-color: $NAVY;

  &:hover {
    background-color: $NAVY;
  }

  &.followed {
    color: $NAVY;
    background-color: #fff;

    &:hover {
      background-color: #fff;
    }
  }
}