@import "app/constants";

.frame {
  background-color: #111;
}

.section {
  height: 400px;
  padding: 48px 32px 0;
  position: relative;
  @media screen and (max-width: $MOBILE_MAX_WIDTH) {
    height: initial;
    padding: 48px 16px 0;
  }

  .image {
    height: 352px;
    position: relative;
    width: calc(50vw - 32px);

    @media screen and (max-width: $MOBILE_MAX_WIDTH) {
      height: 200px;
      margin-bottom: 24px;
      width: initial;
    }
  }

  .meta {
    position: absolute;
    right: 32px;
    top: 150px;
    width: calc(50vw - 64px);

    @media screen and (max-width: $MOBILE_MAX_WIDTH) {
      position: relative;
      right: initial;
      top: initial;
      width: initial;
    }

    .header {
      height: 28px;
      margin-bottom: 20px;
      width: 60%;
    }

    .description {
      .sentence {
        height: 16px;
        margin-bottom: 8px;
        position: relative;

        &:last-child {
          width: 40%;
        }
      }
    }
  }
}

.block {
  background-color: #222;
  animation:blink 1s ease-in-out infinite alternate;
}

@keyframes blink{
  0% { opacity: 0; }
  100% { opacity: 1; }
}
