@import "../../app/constants.scss";

.frame {
  background-color: $BACKGROUND_BLACK;
  bottom: 0;
  color: white;
  left: 0;
  // For iPhoneX needs padding bottom.
  padding-bottom: env(safe-area-inset-bottom, 0px);
  position: fixed;
  width: 100%;

  .inner {
    display: flex;
  }

  a {
    color: white;
    display: block;
    flex-grow: 1;
  }
}

.cell {
  @include CAPTION;
  flex-grow: 1;
  padding: 8px;
  text-align: center;
}
