@import "app/constants.scss";

.frame {
  position: relative;
  display: inline-block;

  &.hasImage {
    .iconCircle {
      background-color: transparent;
      color: transparent;
    }
  }
}

.iconCircle {
  background-color: #a8a8a8;
  border-radius: 18px;
  color: white;
  display: inline-block;
  font-family: "Roboto", Arial;
  font-size: 16px;
  height: 36px;
  line-height: 1;
  padding: 10px;
  text-align: center;
  width: 36px;

  &.medium {
    font-size: 28px;
    border-radius: 24px;
    height: 48px;
    padding: 10px;
    width: 48px;
  }

  &.large {
    font-size: 60px;
    border-radius: 50px;
    height: 100px;
    padding: 20px;
    width: 100px;
  }
}

.imgCircle {
  background-color: white;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}
