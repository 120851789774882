@import "app/constants";

.frame {
  padding-top: calc(68px + env(safe-area-inset-top, 0px));
  color: $DARK_THEME_TEXT_COLOR;
  height: 100vh;
}

.infoRow {
  min-height: 234px;
}

.menuIcon {
  position: absolute;
  right: 8px;
}

.contentContainer {
  padding-bottom: $FOOTER_HEIGHT;
}

.emptyMessage {
  margin-top: 40px;
  padding: 12px;
  text-align: center;

  .header {
    @include H6;
    margin-bottom: 8px;
  }
  .sentence {
    @include BODY_1;
  }
}

.spinnerContainer {
  padding: 24px;
  text-align: center;
}
