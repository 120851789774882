@import "app/constants";

$MAX_WIDTH_VERTICAL_CARD_LIST: 800px;

.frame {
  background-color: #111;
  padding: 4px 4px 0;
  position: relative;
  width: 100vw;
}

.block {
  animation:blink 1s ease-in-out infinite alternate;
  background-color: #222;
  display: inline-block;
  margin-bottom: 4px;
  margin-right: 4px;
  width: calc((100vw - 4px * 2 - 4px * 2) / 3);
  height: calc((100vw - 4px * 2 - 4px * 2) / 3 * 9 / 16);
  &:nth-child(3n) {
    margin-right: 0;
  }

  @media screen and (max-width: $MOBILE_MAX_WIDTH) {
    width: calc(100vw - 4px * 2);
    height: calc((100vw - 4px * 2) * 9 / 16);
  }
}

@keyframes blink{
  0% { opacity: 0; }
  100% { opacity: 1; }
}
