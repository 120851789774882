@import "app/constants";

.frame {
  color: $DARK_THEME_TEXT_COLOR;
  height: calc(100vh - 54px);
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 600px;
  padding: 0 32px;

  @media screen and (max-width: 600px) {
    width: 100%
  }
}

.header {
  @include H6;
  text-align: center;
  margin-bottom: 23px;
}

.textfield {
  margin-bottom: 23px !important;
}

.message {
  position: relative;
  top: 8px;
  text-align: center;
}

.link {
  left: 4px;
  position: relative;
  color: $VIVID_BLUE;
}

.btn {
  margin-top: 23px;
}

.errorContainer {
  color: $ERROR_RED;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 23px;
  text-align: left;
}

.caption {
  @include BODY_1;

  bottom: 64px;
  font-size: 12px;
  position: relative;
  text-align: center;
  top: 24px;
  width: 100%;

  .link {
    color: $VIVID_BLUE;
    display: inline-block;
  }
  .separater {
    display: inline-block;
    margin: 0 8px 0 16px;
  }
}
