.frame {
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #444;
  font-size: 12px;
  height: initial;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;

  &.collapseEnabled.collapsed {
    height: 95px;
  }

  &.collapseEnabled {
    padding-bottom: 24px;
  }
}

.inner {
  padding: 4px 8px;
}

.block {
  border: none;
  margin: 0;
}

.collapseRow {
  background-color: #efefef;
  bottom: 0px;
  box-shadow: 0 -1px 1px -1px rgba(0, 0, 0, 0.4);
  height: 24px;
  position: absolute;
  width: 100%;
}

.collapseButton {
  color: black;
  position: absolute;
  right: 0;

  svg {
    transition: 0.4s;
    transform: rotate(180deg);
  }

  .text {
    display: inline-block;
    margin-right: 4px;
    vertical-align: middle;
  }

  &.collapsed {
    svg {
      transform: rotate(0deg);
    }
  }
}
