@import "app/constants";

.frame {
  background-color: $BACKGROUND_BLACK;
  min-height: 100vh;
  padding-bottom: 12px;
  padding-top: calc(58px + env(safe-area-inset-top, 0px));
}

.header {
  @include H6;
  color: $DARK_THEME_TEXT_COLOR;
  margin: 0 8px 4px;
  max-width: 1440px;

  @media screen and (min-width: 1441px) {
    margin: 16px auto 4px;
  }
}

.subHeader {
  @include BODY_1;
  color: $DARK_THEME_TEXT_COLOR;
  margin-left: 8px;
  margin-bottom: 4px;
  margin-top: 8px;

  &.withTopMargin {
    margin-top: 40px;
  }
}

.noScenesMessage {
  color: $DARK_THEME_TEXT_COLOR;
  text-align: center;
  padding: 24px 0;

  .message {
    @include H6;
    margin-bottom: 12px;
  }
}
