@import "app/constants";

.frame {
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (min-width: #{$MOBILE_MAX_WIDTH + 1px}) {
    padding: 10px;
  }
}

.floatingOpener {
  svg {
    color: white;
  }
}

.floatingBox {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, .2);
  left: 4px;
  position: fixed;
  padding: 0px 48px;
  top: -50px;
  transition: .3s;
  width: calc(100% - 8px);
  z-index: $ZINDEX_MOBILE_SEARCH_BOX;
  color: $BLACK;

  &.shown {
    top: calc(env(safe-area-inset-top, 0px) + 4px);
  }

  .textInput {
  	background-color: transparent;
  	border-style: none;
  	color: inherit;
    font-size: 16px;
    line-height: 1;
    padding: 15px 0;
    outline: none;
    width: 100%;
  }

  .search {
    left: 0;
    position: absolute;
    top: 0;
  }

  .clear {
    position: absolute;
    right: 0;
    top: 0;
  }

  button {
    color: unset;
  }
}

.fixedBox {
  border: 1px solid white;
  border-radius: 4px;
  color: white;
  position: relative;
  display: inline-block;
  height: 36px;
  width: 200px;
  padding-right: 34px;

  .textInput {
    background-color: transparent;
    border-style: none;
    color: inherit;
    font-size: 16px;
    line-height: 1;
    outline: none;
    padding: 8px 0 8px 8px;
  }

  .iconContainer {
    cursor: pointer;
    height: 34px;
    padding: 5px;
    position: absolute;
    right: 0;
    top: 0;
    width: 34px;

    :hover {
      opacity: .8;
    }

    svg {
      display: block;
      height: 24px;
      width: 24px;
    }
  }
}

/* Settings of showing floating and fix type */
@media screen and (min-width: #{$MOBILE_MAX_WIDTH + 1px}) {
  .floatingOpener {
    display: none !important;
  }
  .floatingBox {
    display: none;
  }
  .fixedBox {
    display: inline-block;
  }
}

@media screen and (max-width: #{$MOBILE_MAX_WIDTH}) {
  .floatingOpener {
    display: inline-block;
  }
  .floatingBox {
    display: inline-block;
  }
  .fixedBox {
    display: none;
  }
}
