@import 'app/constants.scss';

/**
 * カードとカードの間の間隔
 * 複数カラムのときは画面の両端にも同サイズのマージンを作る
 */
$GAP: 4;

/**
 * カード表示最小幅 (単位 px)
 */
$MIN_CARD_WIDTH: 320;

/**
 * １カラム表示での最大幅 (単位 px)
 * これを超えると２カラムになる
 */
$ONE_COLUMN_THRESHOLD: $MIN_CARD_WIDTH * 2 + $GAP * 3 - 1;

/**
 * ２カラム表示での最大幅 (単位 px)
 * これを超えると３カラムになる
 */
$TWO_COLUMNS_THRESHOLD: $MIN_CARD_WIDTH * 3 + $GAP * 4 - 1;

/**
 * ３カラム表示での最大幅 (単位 px)
 * これ以上のスクリーンサイズのときはこの値を超える右左の領域はマージンになる。
 * 調整したいときはこの値をいじってください
 */
$THREE_COLUMNS_THRESHOLD: 1440;

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: left;
  margin: 0 auto;
  width: #{$THREE_COLUMNS_THRESHOLD}px;

  @media (max-width: #{$THREE_COLUMNS_THRESHOLD}px) {
    margin: 0 2px;
    width: initial;
  }

  @media (max-width: #{$ONE_COLUMN_THRESHOLD}px) {
    display: initial;
    width: initial;
    margin: 0;
  }
}

.frame {
  border-radius: 1px;
  cursor: pointer;
  height: calc((#{$THREE_COLUMNS_THRESHOLD}px - 4px * 3) / 3 * 9 / 16);
  margin: 0 2px 4px;
  overflow: hidden;
  position: relative;
  width: calc((#{$THREE_COLUMNS_THRESHOLD}px - 4px * 3) / 3);

  &:hover {
    .thumbnail {
      height: 110%;
      left: -5%;
      top: -5%;
      width: 110%;
    }
  }

  @media (min-width: #{$TWO_COLUMNS_THRESHOLD + 1}px) and (max-width: #{$THREE_COLUMNS_THRESHOLD}px) {
    margin: 0 2px 4px;
    height: calc((100vw - 4px * 4) / 3 * 9 / 16);
    width: calc((100vw - 4px * 4) / 3);
  }

  @media (min-width: #{$ONE_COLUMN_THRESHOLD + 1}px) and (max-width: #{$TWO_COLUMNS_THRESHOLD}px) {
    margin: 0 2px 4px;
    height: calc((100vw - 4px * 3) / 2 * 9 / 16);
    width: calc((100vw - 4px * 3) / 2);
  }

  @media (max-width: #{$ONE_COLUMN_THRESHOLD}px) {
    margin: 0 0 4px;
    height: 56.25vw;
    width: 100%;
  }
}

.background {
  background-color: #222;
  background-size: 100px;
  background-position: center;
  background-repeat: no-repeat;
  display: table-cell;
  height: 100%;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.contentContainer {
  color: white;
  box-sizing: border-box;
  bottom: 0;
  font-family: 'Roboto', Arial;
  padding: 8px 8px 24px 12px;
  position: absolute;
  text-align: left;
  width: 100%;
}

.thumbnail {
  background-size: cover;
  font-family: 'object-fit: cover;';
  height: 100%;
  left: 0;
  object-fit: cover;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: .3s;
  width: 100%;

  &.visible {
    opacity: 1;
  }
}

.title {
  color: white;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 26px;
  margin-bottom: 8px;
  word-break: break-all;
}

.userIcon {
  position: absolute;
  left: 8px;
}

.username {
  @include BODY_2;

  padding-left: 40px;
  padding-top: 8px;

  span {
    color: white;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.statsRowContainer {
  position: absolute;
  bottom: 0;
  right: 8px;
  display: flex;
}

.shadowCover {
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 50%, #212121 100%);
  height: 100%;
  position: absolute;
  width: 100%;
}

.iconButton {
  cursor: pointer;
  display: inline-block;
  margin-right: 12px;
  padding: 4px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    height: 36px;
    width: 36px;

    path {
      transition: .4s;
    }
  }
}
