@import "app/constants";

$MAX_WIDTH_VERTICAL_CARD_LIST: 800px;

.frame {
  background-color: #111;
  padding: 56px 0 0 16px;
  position: relative;
  width: 100vw;
}

.block {
  background-color: #222;
  animation:blink 1s ease-in-out infinite alternate;
}

@keyframes blink{
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.pickupList {
  overflow: hidden;

  .header {
    .title {
      height: 28px;
      width: 200px;
    }
  }
  .list {
    margin: 8px 0 16px;
    position: relative;
    width: 1000px;

    .card {
      border-radius: 4px;
      display: inline-block;
      margin-right: 8px;
      height: 252px;
      position: relative;
      width: 156px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
