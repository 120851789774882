@import "app/constants.scss";

.frame {
  background: linear-gradient(#111a, #1110);
  color: white;
  left: 0;
  // For iPhoneX needs padding top.
  padding-top: env(safe-area-inset-top, 0px);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: $ZINDEX_MENU;

  .inner {
    position: relative;
    padding: 12px 0;
  }

  img {
    height: 30px;
  }

  &.filled {
    background-color: #111a;
  }
}

.backButton {
  padding: 4px;
  position: absolute;
  left: 0;
  top: 0;

  button {
    color: white;
  }
}
