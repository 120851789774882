@import "../../app/constants.scss";

.frame {
  color: $VIVID_BLUE;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  line-height: 1;
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 8px 0;

  &.important {
    background-color: $VIVID_BLUE;
    border-radius: 16px;
    color: white;
    padding: 8px 12px;
  }
}
