@import "app/constants.scss";

svg.good {
  transition: .4s;
  &.gooded {
    transform: rotateY(360deg);
    fill: $PINK;
  }
  &:hover {
    @media (hover: hover) {
      fill: $LIGHT_PINK;
    }
  }
}
