table {
  border: 1px solid #555;

  tr {
    border-bottom: 1px solid #555;
  }

  td,
  th {
    border-right: 1px solid #555;
    padding: 5px;
  }

  th {
    background-color: #ddd;
  }
}

code:not(.hljs) {
  padding: 4px;
  border: 1px solid #bbbb;
  border-radius: 2px;
  background-color: #f2f2f2;
  color: #555;
  font-size: 12px;
}

blockquote {
  color: #999;
  margin-left: 0;
  border-left: 3px solid gray;
  padding-left: 12px;
}
