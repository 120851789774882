@import "app/constants";

.filterSvg {
  height: 1px;
  position: absolute;
  width: 1px;
}

.frame {
  background-color: #111;
  color: white;
  font-family: Roboto;
  height: 120vh;
  max-height: 1000px;
  position: relative;

  .inner {
    bottom: 24px;
    position: absolute;
    width: 100%;
  }
}

.frame.show {
  .image {
    opacity: 1;
    transition: 0.4s;
  }
  .meta {
    opacity: 1;
    top: 0;
    transition: 0.4s linear;
  }
}

.image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
  position: absolute;
  width: 100%;
  opacity: 0;
  transition: 0.4s;

  .cover {
    background: linear-gradient(0deg, #111f 0%, #1110 50%);
    height: 100%;
    left: 0;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.meta {
  max-width: 600px;
  opacity: 0;
  padding: 0 16px;
  position: relative;
  text-align: justify;
  top: 16px;
  transition: 0s;
  width: 50vw;

  @media screen and (max-width: 800px) {
    max-width: initial;
    width: initial;
  }

  .title {
    font-size: 20px;
    word-break: break-all;
  }

  .user {
    margin-bottom: 12px;
    margin-top: 4px;
    padding: 6px 0 6px 44px;
    position: relative;

    .text {
      color: white;

      &:hover {
        text-decoration: underline;
      }
    }

    .iconContainer {
      left: 0;
      position: absolute;
      top: 0;
    }
  }

  .description {
    font-size: 12px;
    margin-top: 12px;
  }

  .buttonContainer {
    margin-top: 12px;

    button {
      margin-right: 8px;

      svg {
        margin-right: 8px;
      }
    }
  }
}


.staffPicksContianer {
  margin-top: 48px;
}

.barContainer {
  box-sizing: border-box;
  padding-right: 8px;
  position: absolute;
  top: calc(60px + env(safe-area-inset-top, 0px));
  right: 0;

  .barInteractiveBox {
    cursor: pointer;
    display: inline-block;
    margin: 0 2px;
    opacity: .6;
    padding: 12px 0;
    position: relative;

    &.selected {
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }

    .bar {
      background-color: white;
      height: 2px;
      width: 16px;
    }
  }
}
