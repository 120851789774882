@import "../../../app/constants";

.frame {
  position: relative;
}

.header {
  margin: 16px 16px 8px;

  .image {
    background-size: contain;
    height: 26px;
    width: 100px;
  }

  .text {
    @include H6;
    color: #eee;
    width: calc(100% - 58px);

    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    @include CAPTION;
    color: #bbb;
    margin-top: 4px;
  }

  .viewAll {
    color: $VIVID_BLUE;
    font-size: 12px;
    line-height: 16px;
    line-height: 1em;
    position: absolute;
    right: 8px;
    top: 5px;

    svg {
      height: 16px;
      vertical-align: sub;
      width: 16px;
    }
  }
}

.container {
  padding: 0 16px;
  position: relative;
  overflow-x: scroll;
}

.list {
  position: relative;

  a {
    display: inline-block;
  }
}

.card {
  background-color: #222;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  display: inline-block;
  height: 252px;
  margin-right: 8px;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  width: 156px;

  .thumbnail {
    border: none;
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: 0;
    outline: none;
    position: absolute;
    transition: .4s;
    top: 0;
    width: 100%;
  }

  .meta {
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.9) 100%);
    bottom: 0;
    box-sizing: border-box;
    color: white;
    position: absolute;
    transition: .3s;
    width: 100%;

    .title {
      @include BODY_1;
      line-height: 20px;
      padding: 8px 8px 48px 8px;
      word-break: break-all;
    }

    .accountIconContainer {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }

    .statsRowContainer {
      position: absolute;
      bottom: 4px;
      right: 8px;
      display: flex;

      button {
        padding: 2px;
      }
    }

    .buttonContainer {
      bottom: 8px;
      position: absolute;
      right: 8px;

      svg {
        height: 32px;
        width: 32px;

        &:first-child {
          margin-right: 8px;
        }
      }
    }
  }
}
